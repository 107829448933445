<template>
  <b-container fluid ref="jobDescription">
    <b-row class="justify-content-center">
      <b-col md="8">
        <timeline-component :job="value" class="mb-5" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group :label="$t('status')">
          <b-input-group>
            <b-form-select
              v-e2e:changeStatus
              v-model="status"
              :options="statusesList"
              :disabled="cancellationSelected || !!moreActionsSelectedValue"
            />
            <b-input-group-append v-if="!statusChanged">
              <b-button
                variant="primary"
                v-b-tooltip.hover
                :disabled="cancellationSelected"
                title="Edit associated values"
                @click="prepareChange()"
              >
                <i class="material-icons">edit</i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group :label="$t('cancellation')">
          <multiselect
            v-model="selectedCancellation"
            :options="cancellations"
            :searchable="true"
            :placeholder="'-- ' + $t('choose') + '--'"
            track-by="sfid"
            :custom-label="
              ({ name, description }) => `${name} - ${description[locale]}`
            "
            :showLabels="false"
            :disabled="!!change && !cancellationSelected"
          />
          <template slot="description" v-if="lastCancellation">
            {{ lastCancellation }}
            <b-link @click="$refs.cancellationsList.show()">History</b-link>
          </template>
        </b-form-group>
        <cancellations-list
          ref="cancellationsList"
          :cancellations-history="cancellationsHistory"
        />
        <b-form-group :label="$t('job.more-actions')">
          <b-input-group>
            <multiselect
              v-e2e:jobMoreActions
              class="gid-more-actions"
              group-values="options"
              group-label="type"
              :group-select="false"
              v-model="moreActions"
              :options="moreActionsByGroup"
              track-by="text"
              label="text"
              :multiple="false"
              :placeholder="`-- ${$t('choose')} --`"
              select-label=""
              deselect-label=""
              :disabled="!!change || cancellationSelected"
            >
              <template slot="option" slot-scope="props">
                <span v-if="props.option.$isLabel">
                  -- {{ props.option.$groupLabel }} --
                </span>
                <span v-else>{{ props.option.text }}</span>
              </template>
            </multiselect>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Activities">
          <b-button
            variant="primary"
            @click="$refs.activityFeed.addActivity('comment')"
          >
            {{ $t('comments.new') }}
          </b-button>
        </b-form-group>
        <b-form-group label="Tickets">
          <live-agent-ticket-new
            :jobs="[item]"
            :jobNames="[item.job.name]"
            :visible="showAddTicket"
            :job-contacts="jobContacts"
            context="admin"
            @ticket-created="onRefreshTickets"
            @close="showAddTicket = false"
          />
          <b-button variant="primary" @click="showAddTicket = true">
            {{ $t('tickets.new') }}
          </b-button>
        </b-form-group>

        <b-form-group label="Chat">
          <template v-if="loading">
            <!-- Add spinner with animation -->
            <b-spinner small label="Loading assistant..." class="my-3" />
            <p>{{ $t('Assistant.....') }}</p>
          </template>
          <template v-else-if="!assistantId">
            <b-button variant="primary" @click="createAssistant">
              Assistant
            </b-button>
          </template>
          <transition name="fade-scale">
            <ChatView
              v-if="assistantId"
              :assistantId="assistantId"
              :messages="messages"
              @updateMessages="updateMessages"
            />
          </transition>
        </b-form-group>
      </b-col>
      <b-col md="9">
        <b-alert v-if="change" show :variant="change.type">
          <h3 class="alert-heading">{{ change.title }}:</h3>
          <h5 v-if="change.numAffectedJobs" class="text-danger">
            This change will affect {{ change.numAffectedJobs }} additional jobs
          </h5>
          <p v-if="cancellationSelected">
            {{
              $t('job.cancellation-status-change', {
                status_name: cancellationStatus,
              })
            }}
          </p>
          <b
            v-if="change.instructions && change.instructions[locale]"
            variant="success"
          >
            <div
              v-if="change.instructions[locale]"
              v-html="change.instructions[locale].replace(/\n/g, '<br/>')"
            ></div>
          </b>
          <div
            v-if="change.description && change.description[locale]"
            v-html="change.description[locale].replace(/\n/g, '<br/>')"
          ></div>
        </b-alert>
        <ActivityFeed
          ref="activityFeed"
          class="mb-4"
          :job="value"
          :refreshTickets="refreshTickets"
          @comment-action="onCommentAction"
        />
        <b-form-group
          :label="$t('job.cancellation-target')"
          v-if="cancellationSources.length > 1"
        >
          <b-form-checkbox-group
            class="d-inline"
            v-model="changeTargets"
            :options="cancellationSources"
          />
        </b-form-group>
        <template v-if="change">
          <template v-for="input in change.inputs">
            <input-holder
              :key="input.name"
              :input-name="input.name"
              :title="input.title"
              :type="input.type"
              :edit.sync="input.edit"
              :show="input.show"
              :preview-only="input.previewOnly"
            >
              <div v-if="input.async && input.fetchedChunks.some((x) => !x)">
                Loading...
              </div>
              <component
                v-if="
                  !input.async ||
                  (input.async && input.fetchedChunks.every((x) => x))
                "
                :is="input.component"
                :type="input.type"
                :readonly="!input.edit"
                :input-name="input.name"
                :job-id="value.job.sfid"
                v-bind.sync="input.extra"
              />
            </input-holder>
          </template>
        </template>
        <b-alert
          show
          :variant="changeResult.type"
          v-if="changeResult"
          class="gid-error"
        >
          <h3 class="alert-heading">
            {{ changeResult.title }}
            <a
              target="blank"
              v-if="changeResult.linkTo"
              :href="changeResult.linkTo"
            >
              {{ changeResult.linkToText || changeResult.linkTo }}
            </a>
          </h3>
          <div v-if="changeResult.details">
            {{ changeResult.description }}
            <div>
              <b-collapse id="result-details" class="my-2">
                <b-card>
                  <small>
                    <pre class="overflow-auto">{{ changeResult.details }}</pre>
                  </small>
                </b-card>
              </b-collapse>
              <b-btn v-b-toggle.result-details>
                {{ $t('login.buttons.error-details') }}
              </b-btn>
            </div>
          </div>
        </b-alert>
        <template v-if="change">
          <b-button
            v-e2e:confirmChange
            variant="primary"
            @click="confirmChange"
            :disabled="change.loading"
          >
            <b-spinner
              v-if="change.loading"
              class="gid-spinner--button mr-2"
            ></b-spinner>
            {{ $t('confirm-and-change') }}
          </b-button>
          <b-button variant="secondary" @click="cancelChange" class="ml-2">
            {{ $t('cancel') }}
          </b-button>
        </template>

        <template
          v-if="previewItems.length && !change && !cancellationSources.length"
        >
          <input-holder
            v-for="input in previewItems"
            :key="input.name"
            :input-name="input.name"
            :title="input.title"
            :type="input.type"
            :preview-only="true"
            :show="value.show"
            :edit="input.edit"
          >
            <component
              :is="input.component"
              :value="input.data"
              :type="input.type"
              :readonly="true"
              :input-name="input.name"
              :job-id="value.job.sfid"
              :payment-reminder-blocker="item.job.payment_reminder_blocker"
              :refreshTickets="refreshTickets"
              @message-created="onRefreshTickets"
              v-bind="input.extra"
            />
          </input-holder>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// eslint can't detect the dynamic usage of the components

import axios from 'axios';
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { JobStatusEnum, QuoteTargetEnum, UserRoleEnum } from '@gid/models';
import { StatusRulesNamesEnum } from '@gid/models/dist/entities/status-rules-names-enum';
import { isDKVMissingData } from '@gid/vue-common/utils';
import LiveAgentTicketNew from '../../components/ticketing/LiveAgentTicketNew.vue';
import TicketsList from '../../components/ticketing/TicketsList.vue';
import CancellationsList from './components/CancellationsList';
import InputHolder from './components/InputHolder.vue';
import Documentation from './inputs/Documentation.vue';
import JobOwner from './inputs/JobOwner.vue';

import Address from '@gid/vue-common/components/inputs/Address.vue';
import Appointment from '@gid/vue-common/components/inputs/Appointment.vue';
import AppointmentsList from '@gid/vue-common/components/inputs/AppointmentsList.vue';
import Comments from '@gid/vue-common/components/inputs/Comments.vue';
import FormAnswers from '@gid/vue-common/components/inputs/FormAnswers.vue';
import CustomerDetails from '@gid/vue-common/components/inputs/CustomerDetails.vue';
import DeliveryDate from '@gid/vue-common/components/inputs/DeliveryDate.vue';
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';
import InvoiceContact from '@gid/vue-common/components/inputs/InvoiceContact.vue';
import InvoicesStatus from '@gid/vue-common/components/inputs/InvoicesStatus.vue';
import Partner from '@gid/vue-common/components/inputs/Partner.vue';
import Products from '@gid/vue-common/components/inputs/Products.vue';
import Properties from '@gid/vue-common/components/inputs/Properties.vue';
import ReadinesDate from '@gid/vue-common/components/inputs/ReadinessDate.vue';
import RichTextComments from '@gid/vue-common/components/inputs/RichTextComments.vue';
import Services from '@gid/vue-common/components/inputs/Services.vue';
import SimpleText from '@gid/vue-common/components/inputs/SimpleText.vue';
import TaxProfile from '@gid/vue-common/components/inputs/TaxProfile.vue';
import Timestamp from '@gid/vue-common/components/inputs/Timestamp.vue';
import WallboxPriorities from '@gid/vue-common/components/inputs/WallboxPriorities.vue';

import JobAdditionalItems from '@gid/vue-common/store/job-additional-items';
import {
  JOB_INIT_INPUTS,
  JOB_SET_EDIT,
} from '@gid/vue-common/store/jobs.module';
import { getModule } from 'vuex-module-decorators';
import Timeline from './components/Timeline.vue';

import * as InputTypes from '@gid/vue-common/components/inputs/_types';
import CheckboxWrapper from '@gid/vue-common/components/inputs/CheckboxWrapper.vue';
import MultiselectWrapper from '@gid/vue-common/components/inputs/MultiselectWrapper.vue';

import { toGenericInvoicingTarget } from '@gid/models';
import { CommentActionTypeEnum } from '@gid/models/dist/entities/comment';
import ActivityFeed from '@gid/vue-common/components/ActivityFeed';
import AdditionalItems from '@gid/vue-common/components/inputs/AdditionalItems.vue';
import ChatView from '@gid/vue-common/components/chat-view/chat.vue';

const inputsOrder = [
  InputTypes.INTERNAL_COMMENTS,
  InputTypes.TICKETS_LIST,
  InputTypes.PRODUCTS,
  InputTypes.SERVICES,
  InputTypes.ORDER_EID,
  InputTypes.INVOICING_EID,
  InputTypes.PARTNER_EID,
  InputTypes.PROJECT_ID,
  InputTypes.ORDER_AFFILIATE_ID,
  InputTypes.CUSTOMER_EID,
  InputTypes.TAX_PROFILE,
  InputTypes.PARTNER_INVOICE_ADDRESS,
  InputTypes.CUSTOMER_INVOICE_ADDRESS,
  InputTypes.BRAND_INVOICE_ADDRESS,
  InputTypes.PARTNER_INVOICE_CONTACT,
  InputTypes.CUSTOMER_INVOICE_CONTACT,
  InputTypes.BRAND_INVOICE_CONTACT,
  InputTypes.CUSTOMER_DETAILS,
  InputTypes.CUSTOMER_2_DETAILS,
  InputTypes.FORM_ANSWERS,
  InputTypes.READINESS_DATE,
  InputTypes.DELIVERY_DATE,
  InputTypes.AVAILABILITY_DATES,
  InputTypes.APPOINTMENT_DATE,
  InputTypes.PARTNER_ASSIGNED,
  InputTypes.START_TIMESTAMP,
  InputTypes.FINISHED_TIMESTAMP,
  InputTypes.PROFESSIONAL_COMMENT,
  InputTypes.PLANNING_CUSTOMER,
  InputTypes.PLANNING_PARTNER,
  InputTypes.DOCUMENTATION,
  InputTypes.DOCUMENTATION_PICTURE,
  InputTypes.CANCELLATION_DOCUMENTATION,
  InputTypes.PROPERTIES,
  InputTypes.WALLBOX_PRIORITIES,
  InputTypes.INVOICES_STATUS,
  InputTypes.BRAND_2,
  InputTypes.ADDITIONAL_ITEMS,
  InputTypes.OWN_INSTALLER,
  InputTypes.JOB_OWNER,
];

const eventsWithDetails = [
  'BRAND_PAUSE',
  'CUSTOMER_PAUSE',
  'PARTNER_PAUSE',
  'CUSTOMER_PREWORK_AWAITING',
  'PARTNER_INFO_AWAITING',
  'CUSTOMER_WAITING_PRODUCT',
  'CUSTOMER_WAITING_SUBSIDY',
  'PARTNER_QUOTE_TO_VALIDATE',
  'NETWORK_OPERATOR_WAITING_RESPONSE',
  'AGREEMENT_OWNER_AWAITING',
  'CUSTOMER_PREWORK_BEFORE_ACCEPTED_AWAITING',
  'CUSTOMER_INFO_AWAITING',
  'CUSTOMER_INFO_GENERIC_AWAITING',
  'BRAND_INFO_GENERIC_AWAITING',
  'PARTNER_INFO_GENERIC_AWAITING',
  'PARTNER_ONBOARDING_PENDING',
  'BRAND_PRODUCT_ORDERING_WAITING',
  'CUSTOMER_UNREACHABLE',
  'HOME_ELECTRICIAN_UNREACHABLE',
];

export default {
  components: {
    'input-delivery_date': DeliveryDate,
    'input-appointment_date': Appointment,
    'input-availability_dates': AppointmentsList,
    'input-professional_comment': Comments,
    'input-internal_comments': RichTextComments,
    'input-timestamp': Timestamp,
    'input-partner_assigned': Partner,
    'input-products': Products,
    'input-services': Services,
    'input-customer_details': CustomerDetails,
    'input-customer_2_details': CustomerDetails,
    'input-form_answers': FormAnswers,
    'input-files_selector': FilesSelector,
    'input-documentation': Documentation,
    'input-simple_text': SimpleText,
    'input-tax_profile': TaxProfile,
    'input-address': Address,
    'input-invoice_contact': InvoiceContact,
    'input-properties': Properties,
    'input-invoices-status': InvoicesStatus,
    'input-readiness_date': ReadinesDate,
    'input-multiselect': MultiselectWrapper,
    'input-checkbox': CheckboxWrapper,
    'input-wallbox-priorities': WallboxPriorities,
    'timeline-component': Timeline,
    'input-tickets_list': TicketsList,
    'input-additional_items': AdditionalItems,
    'input-job_owner': JobOwner,
    CancellationsList,
    InputHolder,
    ActivityFeed,
    LiveAgentTicketNew,
    ChatView,
  },
  props: {
    value: Object,
    jobContacts: Array,
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    additionalItemsStore() {
      return getModule(JobAdditionalItems, this.$store);
    },
    moreActionsByStatus() {
      let actionsByGroup = {
        price: {
          type: this.$t('job.more-actions-group.prices'),
          options: ['reset_prices'],
        },
        proposals: {
          type: this.$t('job.more-actions-group.proposals'),
          options: ['create_proposal', 'create_alternative'],
        },
        emails: {
          type: this.$t('job.more-actions-group.email'),
          options: [],
        },
        others: {
          type: this.$t('job.more-actions-group.others'),
          options: [],
        },
      };
      if (!this.isReclamation) {
        actionsByGroup.proposals.options.push('create_reclamation');
      }
      if (this.status === JobStatusEnum.PROPOSAL_APPROVAL_PENDING) {
        actionsByGroup.emails.options.push('trigger_quote');
        actionsByGroup.emails.options.push('trigger_quote_domainissue');
      } else if (
        this.status === JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING
      ) {
        actionsByGroup.emails.options.push('create_brand_quote');
      } else if (this.status === JobStatusEnum.NEW) {
        actionsByGroup.emails.options.push('trigger_email');
        actionsByGroup.emails.options.push('trigger_partner_notification');
      } else if (
        this.status === JobStatusEnum.ON_HOLD &&
        this.item.job.states?.list?.includes(
          `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.missing-customer`,
        )
      ) {
        actionsByGroup.emails.options.push('send_reminder_absent_customer');
      } else if (
        this.status === JobStatusEnum.ON_HOLD &&
        this.item.job.states?.list?.includes(
          `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.modification`,
        )
      ) {
        actionsByGroup.emails.options.push('send_reminder_job_modified');
      }
      if (isDKVMissingData(this.item)) {
        actionsByGroup.emails.options.push('send_dkv_missing_sap_number');
      }

      return Object.values(actionsByGroup).map((item) => ({
        type: item.type,
        options: item.options.map((optionName) => ({
          text: this.$t(this.moreActionsOptions[optionName].labelKey),
          value: optionName,
        })),
      }));
    },
    moreActionsByGroup() {
      return [
        ...this.moreActionsByStatus,
        {
          type: this.$t('job.more-actions-group.state'),
          options: this.jobServiceEvents.map((event) => ({
            text: this.$t(`state-events.${event}`),
            value: {
              funcName: 'sendJobServiceEvent',
              funcParams: { event },
            },
          })),
        },
      ];
    },
    moreActionsSelectedValue() {
      return this.moreActions ? this.moreActions.value : this.moreActions;
    },
    statusChanged() {
      return this.status != this.item.job.status;
    },
    cancellation() {
      return this.selectedCancellation && this.selectedCancellation.id;
    },
    cancellationSelected() {
      return this.cancellation != null;
    },
    cancellationStatus() {
      let cancellation = this.cancellations.find(
        (item) => item.id == this.cancellation,
      );
      let status = this.statusesList.find(
        (item) => item.value == cancellation.forces_status,
      );
      return status ? status.text : 'no change';
    },
    cancellationsHistory() {
      return this.item.cancellations.map((cancellation) => {
        const cancellationDef = this.cancellations.find(
          (item) => item.id == cancellation.cancellation_reason_opportunity_id,
        );
        return {
          ...cancellation,
          definition: cancellationDef,
        };
      });
    },
    cancellationSources() {
      return this.selectedCancellation?.sources ?? [];
    },
    lastCancellation() {
      if (this.cancellationsHistory.length > 0) {
        const cr = this.cancellationsHistory[0];
        if (cr.definition) {
          return this.$t('job.last-cancellation', {
            cancellation_reason: `${cr.definition.name} - ${
              cr.definition.description[this.locale]
            }`,
          });
        }
      }
      return false;
    },
    allowDuplicateServices() {
      // For now allow this only for th gardena hedges opportunity
      return this.item && this.item.opportunity.sfid === '0061p00000mv76wAAA';
    },
    invocingTargets() {
      if (this.item) {
        const invocingTargetsPresent = this.item.services.reduce(
          (acc, svc) => ({ ...acc, [svc.invoicing_target]: true }),
          {},
        );
        return Object.keys(invocingTargetsPresent);
      }
      return [];
    },
  },
  watch: {
    value() {
      this.init();
    },
    status() {
      if (this.statusChanged) {
        this.prepareChange();
      } else {
        this.change = null;
      }
    },
    cancellation() {
      this.change = null;
    },
    cancellationSources(sources) {
      this.changeTargets = [...sources];
    },
    moreActionsSelectedValue(newAction) {
      if (newAction == 'reset_prices') {
        this.prepareChange();
      } else if (newAction?.funcName == 'sendJobServiceEvent') {
        const statusEventLabel = this.$t(
          `state-events.${newAction.funcParams.event}`,
        );
        const actionLabel = this.$t('state-events-send', {
          event: statusEventLabel,
        });
        this.confirmAction(
          this.$t('job.confirm-action', { action: actionLabel }),
          newAction,
        );
      } else if (newAction != null) {
        const actionOptions = this.moreActionsOptions[newAction];
        this.confirmAction(
          this.$t('job.confirm-action', {
            action: this.$t(actionOptions.labelKey),
          }),
          actionOptions,
        );
      } else {
        this.change = null;
      }
    },
    changeTargets(newTargets) {
      if (this.cancellationSelected) {
        if (
          newTargets.length > 0 ||
          (newTargets.length == 0 && this.cancellationSources.length == 0)
        ) {
          this.prepareChange();
        } else {
          this.change = null;
        }
      }
    },
  },
  data() {
    return {
      item: null,
      status: null,
      isReclamation: false,
      statusesList: [],
      selectedCancellation: null,
      cancellations: [],
      change: null,
      changeResult: null,
      moreActions: null,
      new_custom_services: [],
      jobSelections: [],
      moreActionsOptions: {
        reset_prices: {
          labelKey: 'job.reset-prices',
        },
        create_proposal: {
          labelKey: 'job.create-proposal',
          funcName: 'createProposal',
        },
        create_reclamation: {
          labelKey: 'job.create-reclamation',
          funcName: 'createReclamation',
        },
        create_alternative: {
          labelKey: 'job.create-alternative',
          funcName: 'createAlternative',
        },
        trigger_email: {
          labelKey: 'job.send-planning-upload',
          funcName: 'sendEmailOrderPlanning',
        },
        trigger_partner_notification: {
          labelKey: 'job.trigger-partner-notification',
          funcName: 'sendEmailPartnerNotification',
        },
        trigger_quote: {
          labelKey: 'job.send-quote',
          funcName: 'sendEmailOrderQuote',
        },
        trigger_quote_domainissue: {
          labelKey: 'send domain issue quote',
          funcName: 'sendEmailOrderQuoteDomainIssue',
        },
        send_dkv_missing_sap_number: {
          labelKey: 'job.send-dkv-missing-sap-number',
          funcName: 'sendEmailDKVMissingSapNumber',
        },
        create_brand_quote: {
          labelKey: 'job.create-brand-quote',
          funcName: 'createBrandQuote',
        },
        send_reminder_absent_customer: {
          labelKey: 'job.send-reminder-absent-customer',
          funcName: 'sendReminderAbsentCustomer',
        },
        send_reminder_job_modified: {
          labelKey: 'job.send-reminder-job-modified',
          funcName: 'sendReminderJobModified',
        },
      },
      jobServiceEvents: [],
      changeTargets: [],
      previewItems: [],
      showAddTicket: false,
      refreshTickets: false,
      assistantId: '',
      messages: [],
      loading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.item = this.value;
      this.status = this.item.job.status;
      this.isReclamation = this.item.job.is_reclamation;

      // Proceed with initialization tasks
      return Promise.allSettled([
        this.fetchJob(),
        this.fetchStatuses(),
        this.fetchCancellations(),
        this.fetchJobServiceEvents(),
      ]).then(() => {
        this.initPreview(); // Initialize the preview after all fetches are complete

        //  return this.createAssistant(); // Create the assistant after initializing the preview
      });
    },
    updateMessages(newMessages) {
      this.messages = newMessages;
    },

    async createAssistant() {
      this.loading = true; // Start spinner
      try {
        // Generate the job context
        const jobContext = await this.generateJobContext();

        // Create a Blob from the jobContext string
        const blob = new Blob([jobContext], { type: 'text/plain' });

        // Create a file object from the Blob
        const file = new File([blob], `Job_${this.item.job.sfid}_Context.txt`, {
          type: 'text/plain',
        });

        // Create FormData and append the file
        const formData = new FormData();
        formData.append('name', `Job_${this.item.job.sfid}_Assistant`);
        formData.append(
          'instructions',
          'Please refer to the attached file for detailed job context.',
        );
        formData.append('file', file);

        const response = await axios.post(
          '/assistant-api/create_assistant',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        this.assistantId = response.data?.assistant?.id;
      } catch (error) {
        console.error('Error creating assistant:', error);
      } finally {
        this.loading = false; // Stop loading spinner
      }
    },

    async generateJobContext() {
      // Helper function to flatten a nested object
      const flattenObject = (obj, parentKey = '') => {
        const result = {};

        for (const key in obj) {
          const newKey = parentKey ? `${parentKey}.${key}` : key;

          if (Array.isArray(obj[key])) {
            // Handle arrays by recursively flattening each element
            result[newKey] = obj[key]
              .map((item, index) =>
                typeof item === 'object' && item !== null
                  ? `{ ${Object.entries(
                      flattenObject(item, `${newKey}[${index}]`),
                    )
                      .map(([subKey, subValue]) => `${subKey}: ${subValue}`)
                      .join(', ')} }`
                  : `${item}`,
              )
              .join(', ');
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Recursively flatten sub-objects
            const flattenedSubObject = flattenObject(obj[key], newKey);
            Object.assign(result, flattenedSubObject);
          } else {
            // Add primitive values directly
            result[newKey] = obj[key];
          }
        }

        return result;
      };

      // Flatten the jobDetails object
      const flattenedJobDetails = flattenObject(this.item);

      // Construct the context string
      const contextString = Object.entries(flattenedJobDetails)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');

      return contextString;
    },
    async fetchJob() {
      return axios
        .get(`/api/admin/jobs/${this.item.job.api_id}`)
        .then((response) => {
          this.item = response.data;
        });
    },
    async fetchStatuses() {
      return axios
        .post(`/api/admin/jobs/set-status`, {
          preview: true,
          job_id: this.item.job.sfid,
          target: null,
        })
        .then((response) => {
          this.statusesList = response.data.statuses.map((status) => ({
            text: status.gid_name[this.locale],
            value: status.job_status,
            disabled: !status.allowed,
          }));
        });
    },
    async fetchCancellations() {
      return axios
        .get(`/api/admin/jobs/${this.item.job.sfid}/cancellation-reasons`)
        .then((response) => {
          this.cancellations = response.data?.sort((a, b) =>
            b.name.localeCompare(a.name, undefined, {
              sensitivity: 'base',
              numeric: true,
            }),
          );
        });
    },
    fetchJobServiceEvents() {
      return axios
        .get(
          `/jobs-api/status-rules/job/${this.value.job.api_id}/events-available`,
        )
        .then(({ data }) => {
          this.jobServiceEvents = data;
        });
    },
    initPreview() {
      const previewItems = [
        InputTypes.PRODUCTS,
        InputTypes.SERVICES,
        InputTypes.ORDER_EID,
        InputTypes.INVOICING_EID,
        InputTypes.PROJECT_ID,
        InputTypes.ORDER_AFFILIATE_ID,
        InputTypes.CUSTOMER_DETAILS,
        InputTypes.CUSTOMER_2_DETAILS,
        InputTypes.FORM_ANSWERS,
        InputTypes.READINESS_DATE,
        InputTypes.DELIVERY_DATE,
        InputTypes.AVAILABILITY_DATES,
        InputTypes.APPOINTMENT_DATE,
        InputTypes.PARTNER_ASSIGNED,
        InputTypes.START_TIMESTAMP,
        InputTypes.FINISHED_TIMESTAMP,
        InputTypes.PROFESSIONAL_COMMENT,
        InputTypes.PLANNING_CUSTOMER,
        InputTypes.PLANNING_PARTNER,
        InputTypes.DOCUMENTATION,
        InputTypes.CANCELLATION_DOCUMENTATION,
        InputTypes.PROPERTIES,
        InputTypes.WALLBOX_PRIORITIES,
        InputTypes.INVOICES_STATUS,
        InputTypes.BRAND_2,
        InputTypes.TICKETS_LIST,
        InputTypes.OWN_INSTALLER,
      ];
      const includeEmpty = [
        InputTypes.SERVICES,
        InputTypes.TICKETS_LIST,
        InputTypes.READINESS_DATE,
        InputTypes.INTERNAL_COMMENTS,
      ];
      const excludeEmpty = Object.fromEntries(
        previewItems.map((name) => [name, !includeEmpty.includes(name)]),
      );
      const previewObject = previewItems.reduce((items, item) => {
        items[item] = {
          state: 'read_only',
          variants: [],
        };
        return items;
      }, {});

      this.previewItems = this.initInputs(
        previewObject,
        this.item,
        this.changeTargets,
        excludeEmpty,
      );
    },
    initInputs(inputs, job_view, changeTargets, excludeEmpty) {
      const inputTypesRequested = Object.keys(inputs);
      this.jobSelections = job_view.selections;

      if (inputTypesRequested.includes(InputTypes.PROPERTIES)) {
        inputs[InputTypes.WALLBOX_PRIORITIES] = inputs[InputTypes.PROPERTIES];
      }

      let inputsArray = Object.keys(inputs)
        .map((inputName) => {
          if (inputName === 'custom_services') {
            return null;
          }
          const input = inputs[inputName];
          const inputType = input.state;
          input.title = this.$t(`inputs.headings.${inputName}`);
          input.show = false;
          let component = `input-${input.component ?? inputName}`;
          let initData = null;
          let extra = null;
          let variants = input.variants;

          if (inputName == InputTypes.START_TIMESTAMP) {
            component = 'input-timestamp';
            initData = job_view.job.date_start;
          } else if (inputName == InputTypes.FINISHED_TIMESTAMP) {
            component = 'input-timestamp';
            initData = job_view.job.date_end;
          } else if (inputName == InputTypes.APPOINTMENT_DATE) {
            initData = {
              start: job_view.job.appointment_start_timestamp,
              end: job_view.job.appointment_end_timestamp,
            };
            extra = {
              disableMinDateConstrains: true,
              multiday: job_view.opportunity.multiday,
            };
          } else if (inputName == InputTypes.DELIVERY_DATE) {
            initData = job_view.job.date_expected;
          } else if (inputName == InputTypes.READINESS_DATE) {
            initData = job_view.job.readiness_date;
          } else if (inputName == InputTypes.AVAILABILITY_DATES) {
            initData = job_view.job.dates;
            extra = {
              disableMinDateConstrains: true,
              multiday: job_view.opportunity.multiday,
            };
          } else if (inputName == InputTypes.PARTNER_ASSIGNED) {
            initData = job_view.partner.id;
            extra = {
              name: job_view.partner.name,
              job_sfid: job_view.sfid,
              job_status: job_view.job?.status,
              job_subStatus: job_view.job?.states?.list,
            };
          } else if (inputName === InputTypes.PRODUCTS) {
            initData = job_view.products;
            const productSelections = job_view.selections.filter(
              (selection) => selection.type === 'product',
            );
            extra = {
              context: 'admin',
              editProducts:
                !variants.includes('partial_selection') &&
                (!variants.includes('invoicing_target') ||
                  variants.includes('service_list')),
              editPriceListing: variants.includes('service_prices'),
              editPriceSelling: variants.includes('service_prices'),
              editPricePurchasing: variants.includes('service_prices'),
              editInvoicingTarget: variants.includes('invoicing_target'),
              allowSelection: variants.includes('partial_selection'),
              revenue: job_view.revenue,
              costs: job_view.costs,
              showTotals: job_view.services.length === 0,
              orderDetails: this.value?.productOrder?.orderDetails,
              jobProducts: job_view.products,
              selections: productSelections,
            };
          } else if (inputName === InputTypes.SERVICES) {
            initData = job_view.services.map((service) => ({
              service: service.sfid,
              id: service?.id || null,
              selections: service.selections,
              quantity: service.quantity,
              price_purchasing: service.price_purchasing,
              price_selling: service.price_selling,
              price_lists: service.price_lists,
              value_entry: service.value_entry || 1,
              api_id: service.api_id,
              product_id: service.product_id,
              invoicing_target: service.invoicing_target,
            }));

            const editClientPrices =
              variants.includes('service_prices') &&
              changeTargets.includes('customer');
            extra = {
              context: 'admin',
              services_endpoint: `/api/admin/services/for-job/${job_view.job.sfid}`,
              currentPriceList: job_view.job.price_lists,
              currentPricePurchasing: job_view.job.price_purchasing,
              currentPriceSelling: job_view.job.price_selling,
              products: job_view.products,
              useExternalNames: false,
              allowSelection: variants.includes('partial_selection'),
              allowCrediting: variants.includes('credit_service'),
              editServices: variants.includes('service_list'),
              editPriceSelling:
                variants.includes('service_prices') &&
                changeTargets.includes('brand'),
              editPricePurchasing:
                variants.includes('service_prices') &&
                changeTargets.includes('partner'),
              editInvoicingTarget: variants.includes('invoicing_target'),
              editPriceList: editClientPrices,
              hidePriceList: false,
              allowDuplicateServices: this.allowDuplicateServices,
              productId: null,
              revenue: job_view.revenue,
              costs: job_view.costs,
              job_view_services: job_view.services.map((s) => ({
                sfid: s.sfid,
                invoicing_target: s.invoicing_target,
              })),
              existent_custom_services: job_view.custom_services,
              custom_services: this.new_custom_services,
              jobServices: job_view.services,
            };
          } else if (inputName === InputTypes.ADDITIONAL_ITEMS) {
            component = 'input-additional_items';
            extra = {
              context: UserRoleEnum.ADMIN,
            };
          } else if (
            inputName === InputTypes.CUSTOMER_DETAILS ||
            inputName === InputTypes.CUSTOMER_2_DETAILS
          ) {
            initData =
              inputName === InputTypes.CUSTOMER_DETAILS
                ? job_view.customer
                : job_view.customer_second?.id
                ? job_view.customer_second
                : null;

            extra = {
              showBillingFields: true,
              allowCustomCoordinates: true,
              contactPerson: {
                first_name: job_view.job_contact_person?.first_name,
                last_name: job_view.job_contact_person?.last_name,
                phone: job_view.job_contact_person?.phone,
                email: job_view.job_contact_person?.email,
                use_for_email: job_view.job_contact_person?.use_for_email,
                use: !!job_view.job_contact_person?.first_name,
                quote_target:
                  job_view.job_contact_person?.quote_target ||
                  QuoteTargetEnum.CUSTOMER,
              },
            };
          } else if (inputName == InputTypes.FORM_ANSWERS) {
            initData = job_view.job.sfid;
            extra = {
              answers: [],
            };
            component = 'input-form_answers';
          } else if (inputName == InputTypes.ORDER_EID) {
            component = 'input-simple_text';
            initData = job_view.job.eid;
          } else if (inputName == InputTypes.INVOICING_EID) {
            component = 'input-simple_text';
            initData = job_view.job.invoicing_eid;
          } else if (inputName === InputTypes.PARTNER_EID) {
            component = 'input-simple_text';
            initData = job_view.job.partner_eid; // show partner eid
          } else if (inputName == InputTypes.PROJECT_ID) {
            component = 'input-simple_text';
            initData = job_view.project.name;
          } else if (inputName == InputTypes.ORDER_AFFILIATE_ID) {
            component = 'input-simple_text';
            initData = job_view.job.affiliate_id;
          } else if (inputName == InputTypes.CUSTOMER_EID) {
            component = 'input-simple_text';
            initData = job_view.customer.eid;
          } else if (inputName == InputTypes.TAX_PROFILE) {
            initData = {
              tax_id: job_view.partner.tax_id,
              tax_exempt: job_view.partner.tax_exempt,
              company_type: job_view.partner.company_type,
              company_name: job_view.partner.company_name,
            };
          } else if (inputName == InputTypes.PROFESSIONAL_COMMENT) {
            initData = job_view.job[inputName];
            input.show = !!initData;
          } else if (inputName == InputTypes.INTERNAL_COMMENTS) {
            if (this.cancellationSelected) {
              input.title = 'Cancellation Reason Comment';
              input.edit = true;
            }
            extra = {
              hideActions: true,
              hideRoles: true,
              boundValue: {
                message: null,
                visible_for_roles: [UserRoleEnum.BRAND, UserRoleEnum.PARTNER],
                action_type: CommentActionTypeEnum.GENERIC,
                took_action: false,
              },
            };
          } else if (
            [
              InputTypes.PLANNING_CUSTOMER,
              InputTypes.PLANNING_PARTNER,
            ].includes(inputName)
          ) {
            component = 'input-files_selector';
            extra = {
              context: 'admin',
              resultAsArray: true,
            };
          } else if (
            [
              InputTypes.DOCUMENTATION,
              InputTypes.CANCELLATION_DOCUMENTATION,
            ].includes(inputName)
          ) {
            component = 'input-documentation';
            extra = {
              variants,
              context: 'admin',
            };
          } else if ([InputTypes.DOCUMENTATION_PICTURE].includes(inputName)) {
            component = 'input-files_selector';
            extra = {
              variants,
              context: 'admin',
            };
          } else if ([InputTypes.TICKETS_LIST].includes(inputName)) {
            component = 'input-tickets_list';
            extra = {
              jobName: job_view.job.name,
              jobView: job_view,
              context: 'admin',
              query: `job_name:${job_view.job.name || ''}*`,
            };
          } else if (
            [
              InputTypes.BRAND_INVOICE_ADDRESS,
              InputTypes.CUSTOMER_INVOICE_ADDRESS,
              InputTypes.PARTNER_INVOICE_ADDRESS,
            ].includes(inputName)
          ) {
            component = 'input-address';
            const target = inputName.match(/^([^_]+)_/)[1];
            initData = job_view[target].billing_address;
          } else if (
            [
              InputTypes.BRAND_INVOICE_CONTACT,
              InputTypes.CUSTOMER_INVOICE_CONTACT,
              InputTypes.PARTNER_INVOICE_CONTACT,
            ].includes(inputName)
          ) {
            component = 'input-invoice_contact';
          } else if (inputName === InputTypes.PROPERTIES) {
            component = 'input-properties';
            const editableFields = input.editable_fields;
            if (typeof editableFields === 'object') {
              initData = Object.fromEntries(
                Object.entries(editableFields).map(([key, value]) => [
                  key,
                  job_view.properties?.[key],
                ]),
              );
            } else {
              initData = job_view.properties;
            }
            extra = {
              editableFields,
            };
          } else if (inputName === InputTypes.WALLBOX_PRIORITIES) {
            component = 'input-wallbox-priorities';
            initData = job_view.properties;
          } else if (inputName === InputTypes.INVOICES_STATUS) {
            component = 'input-invoices-status';
            initData = job_view.job.sfid;
            const allInvoiceTargets = job_view.services.map(
              (service) => service.invoicing_target,
            );
            extra = {
              allInvoiceTargets: _.uniq([...allInvoiceTargets]),
            };
          } else if (inputName === InputTypes.BRAND_2) {
            component = 'input-multiselect';
            initData = job_view.job.brand_2;
            extra = {
              entityType: 'brand',
            };
          } else if (inputName === InputTypes.OWN_INSTALLER) {
            component = 'input-checkbox';
            (initData = job_view.job.own_installer),
              (extra = {
                label: this.$t('inputs.headings.own_installer'),
              });
          } else if (inputName === InputTypes.JOB_OWNER) {
            component = 'input-job_owner';
            initData = job_view.job.owner;
          }

          if (inputType == 'forced') {
            initData = input.value;
          }

          const componentOutput = {
            name: inputName,
            title: input.title,
            type: inputType,
            data: _.cloneDeep(initData),
            edit: inputType == 'required' || input.edit,
            show: input.show,
            previewOnly: input.previewOnly,
            extra,
            component,
            fetchedChunks: [],
            async: false,
          };

          if (
            [
              InputTypes.PLANNING_CUSTOMER,
              InputTypes.PLANNING_PARTNER,
            ].includes(inputName)
          ) {
            componentOutput.async = true;
            componentOutput.fetchedChunks = [];
            componentOutput.data = [];

            let paths = [];
            if (
              job_view &&
              job_view.files &&
              job_view.files[inputName] &&
              Array.isArray(job_view.files[inputName].paths)
            ) {
              paths = Array.from(job_view.files[inputName].paths);
            }
            componentOutput.fetchedChunks = paths.map((x) => {
              return false;
            });

            const toBase64 = (file) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
              });

            Promise.all(
              paths.map((path) =>
                axios.get(`/api/media/file/${path}?jwt=${this.access_token}`, {
                  responseType: 'blob',
                }),
              ),
            )
              .then(async (files) => {
                componentOutput.data = await Promise.all(
                  (files || [])
                    .map(async (f, index) => {
                      const file = f.data;
                      try {
                        const data = await toBase64(file);
                        const fileInfo = data.match(/data:(.*);base64,(.*)/);
                        let fileMimetype = fileInfo[1];
                        let fileData = fileInfo[2];
                        return {
                          mimetype: fileMimetype,
                          data: fileData,
                        };
                      } catch (err) {
                        console.error(err);
                        return null;
                      } finally {
                        componentOutput.fetchedChunks[index] = true;
                      }
                    })
                    .filter(Boolean),
                );
                this.$forceUpdate();
              })
              .catch((err) => {
                console.log(err);
                componentOutput.async = false;
              });
          } else if (InputTypes.FORM_ANSWERS === inputName) {
            componentOutput.async = true;
            componentOutput.fetchedChunks = [false];

            axios
              .get(
                `/order-intake-forms-api/form/answers/${this.item.job.api_id}`,
              )
              .then((response) => {
                componentOutput.extra.answers = response.data;
              })
              .catch((err) => {
                // TODO: here we could think about an alert message (many other use cases...)
                console.error(err);
              })
              .finally(() => {
                componentOutput.fetchedChunks[0] = true;
              });
          }
          return componentOutput;
        })
        .filter(Boolean);
      if (excludeEmpty) {
        inputsArray = inputsArray.filter(({ name, data }) => {
          if (excludeEmpty[name] === false) {
            return true;
          }
          return data && (Array.isArray(data) ? data.length > 0 : true);
        });
      }

      const excludedIfNull = [InputTypes.CUSTOMER_2_DETAILS];

      inputsArray = inputsArray.filter(({ name, data }) => {
        return !(excludedIfNull.includes(name) && !data);
      });

      inputsArray.sort((a, b) => {
        if (a.edit != b.edit) {
          return a.edit ? -1 : 1;
        } else {
          return inputsOrder.indexOf(a.name) - inputsOrder.indexOf(b.name);
        }
      });

      this.$store.dispatch(JOB_SET_EDIT, {
        init: true,
        jobId: job_view.job.sfid,
        opportunity: job_view.opportunity,
        brandId: job_view.brand.id,
        context: 'admin',
      });
      this.$store.dispatch(JOB_INIT_INPUTS, {
        jobId: job_view.job.sfid,
        inputs: inputsArray,
      });
      this.$store.dispatch('PRODUCT_SUPPLIERS_FETCH');
      this.$store.dispatch('OPPORTUNITY_OPTION_GET', {
        id: job_view.opportunity?.sfid,
      });
      if (
        inputsArray.find(
          (i) => i.name === InputTypes.SERVICES && i.extra?.editInvoicingTarget,
        )
      ) {
        this.$store.dispatch('JOB_LOAD_INVOICING_TARGETS', {
          jobId: job_view.job.sfid,
        });
      }
      this.additionalItemsStore.setItems({
        jobId: job_view.job.sfid,
        items: job_view.additional_items,
      });
      return inputsArray;
    },
    requestMixin() {
      let mixin = {};
      if (this.cancellationSelected) {
        mixin.cancellation_reason_id = this.cancellation;
      } else if (this.moreActionsSelectedValue == 'reset_prices') {
        mixin.reset_price_override = true;
      } else {
        mixin.new_status = this.status;
      }
      mixin.target = this.changeTargets;

      return mixin;
    },
    prepareChange() {
      this.changeResult = null;
      axios
        .post(`/api/admin/jobs/set-status`, {
          preview: true,
          job_id: this.item.job.sfid,
          ...this.requestMixin(),
        })
        .then((response) => {
          this.$refs.activityFeed.hide();
          const data = response.data;
          this.change = {
            type: 'info',
            title: this.$t('description'),
            description: data.message,
            instructions: this.cancellations.reduce((prev, current) => {
              if (this.cancellation === current.id) {
                return current;
              }
              return prev;
            }, {}).instructions,
            inputs: this.initInputs(data.inputs, this.item, this.changeTargets),
            numAffectedJobs: data.affected_jobs && data.affected_jobs.length,
          };
        })
        .catch((error) => {
          if (error.response) {
            let errorsList = [];
            Object.keys(error.response.data.errors).forEach((key) => {
              errorsList = errorsList.concat(error.response.data.errors[key]);
            });
            this.change = {
              type: 'danger',
              title: this.$t('_errors.server.title'),
              description: errorsList.join('\n'),
            };
          } else if (error.request) {
            this.change = {
              type: 'danger',
              title: this.$t('_errors.network.title'),
              description: this.$t('_errors.network.details'),
            };
          }
        });
    },
    cancelChange() {
      this.status = this.item.job.status;
      this.selectedCancellation = null;
      this.change = null;
      this.changeResult = null;
      this.moreActions = null;
      this.initPreview();
    },
    confirmChange() {
      const inputs = this.$store.getters.jobInputsEditedValues(
        this.value.job.sfid,
      );

      this.change = {
        loading: true,
        ...this.change,
      };

      const errorHandler = (error) => {
        if (error.response) {
          this.changeResult = {
            type: 'danger',
            title: this.$t('_errors.server.title'),
            description: this.$t('_errors.server.details'),
            details: error.response.data,
          };
        } else if (error.request) {
          this.changeResult = {
            type: 'danger',
            title: this.$t('_errors.network.title'),
            description: this.$t('_errors.network.details'),
          };
        }
        this.change.loading = false;
      };

      if (this.change.nonStatusAction) {
        const actionFunction = this[this.change.nonStatusAction.funcName];
        let actionFunctionParams = this.change.nonStatusAction.funcParams || {};
        if (actionFunction == this.sendJobServiceEvent) {
          actionFunctionParams.inputs = inputs;
        }
        actionFunction(actionFunctionParams)
          .then(() => {
            let actionText;
            if (actionFunction == this.sendJobServiceEvent) {
              actionText = `Send event ${actionFunctionParams.event}`;
            } else {
              actionText = this.$t(
                this.moreActionsOptions[this.moreActionsSelectedValue].labelKey,
              );
            }
            this.changeResult = {
              type: 'success',
              title: this.$t('job.action-successful', {
                action: actionText,
              }),
            };
            this.change = null;
            this.moreActions = null;
          })
          .catch(errorHandler);
      } else {
        const inputTypesToSave = Object.keys(inputs);
        if (
          inputTypesToSave.includes(InputTypes.PLANNING_CUSTOMER) &&
          Array.isArray(inputs[InputTypes.PLANNING_CUSTOMER])
        ) {
          inputs[InputTypes.PLANNING_CUSTOMER] = {
            files: inputs[InputTypes.PLANNING_CUSTOMER],
          };
        }
        if (
          inputTypesToSave.includes(InputTypes.PLANNING_PARTNER) &&
          Array.isArray(inputs[InputTypes.PLANNING_PARTNER])
        ) {
          inputs[InputTypes.PLANNING_PARTNER] = {
            files: inputs[InputTypes.PLANNING_PARTNER],
          };
        }
        if (inputTypesToSave.includes(InputTypes.WALLBOX_PRIORITIES)) {
          inputs[InputTypes.PROPERTIES] = {
            ...inputs[InputTypes.PROPERTIES],
            ...inputs[InputTypes.WALLBOX_PRIORITIES],
          };
          delete inputs[InputTypes.WALLBOX_PRIORITIES];
        }
        inputs[InputTypes.CUSTOM_SERVICES] = [];
        const customServices = [
          ...this.item.custom_services,
          ...this.new_custom_services,
        ];
        if (customServices.length) {
          inputs[InputTypes.CUSTOM_SERVICES] = customServices.map((service) => {
            if (service.invoicing_target) {
              return {
                ...service,
                invoicing_target:
                  service.invoicing_target[0] ===
                  service.invoicing_target[0].toUpperCase()
                    ? toGenericInvoicingTarget(service.invoicing_target)
                    : service.invoicing_target,
              };
            }
            return {
              ...service,
              invoicing_target: toGenericInvoicingTarget(
                this.item.opportunity.invoicing_target,
              ),
            };
          });
        }

        if (inputs.services && inputs.services.length)
          inputs.services = inputs.services.filter(({ service }) => service);

        if (inputs.products && inputs.products.length)
          inputs.products = inputs.products.filter(({ product }) => product);

        axios
          .post(`/api/admin/jobs/set-status`, {
            preview: false,
            job_id: this.item.job.sfid,
            ...this.requestMixin(),
            inputs,
          })
          .then((response) => {
            this.$emit('input', response.data);
            this.status = this.item.job.status;
            this.selectedCancellation = null;
            this.change = null;
            this.moreActions = null;
            this.new_custom_services = [];
            this.changeResult = {
              type: 'success',
              title: this.$t('update-successful'),
            };
            const scrollCoordinates =
              this.$refs.jobDescription?.getBoundingClientRect().top +
              window.pageYOffset -
              220;
            window.scrollTo({ top: scrollCoordinates });

            if (
              response.data.job &&
              (response.data.job.superseded_by ||
                response.data.job.superseded_by_api_id)
            ) {
              const id =
                response.data.job.superseded_by ||
                response.data.job.superseded_by_api_id;
              this.changeResult.linkTo = `/jobs/${id}`;
              this.changeResult.linkToText = `Job ${id} created`;
            }
          })
          .catch(errorHandler);
      }
    },
    confirmAction(confirmText, action) {
      this.$refs.activityFeed.hide();
      this.changeResult = null;
      this.change = {
        type: 'info',
        title: this.$t('description'),
        description: {
          [this.locale]: confirmText,
        },
        nonStatusAction: action,
      };
      if (
        action.funcName == 'sendJobServiceEvent' &&
        eventsWithDetails.includes(action.funcParams.event)
      ) {
        this.$set(
          this.change,
          'inputs',
          this.initInputs(
            {
              details: {
                state: 'optional',
                component: 'simple_text',
                edit: true,
              },
              resolve_date: {
                state: 'optional',
                component: 'readiness_date',
                edit: true,
              },
            },
            this.item,
          ),
        );
      }
    },
    sendEmailOrderPlanning() {
      axios.post('/api/admin/send-email', {
        template_name: 'customer/order_planning_upload',
        // sender: 'wallbox@getitdone.co',
        job_id: this.value.job.sfid,
      });
    },
    sendEmailOrderQuote() {
      return axios.get(
        `/api/admin/jobs/${this.value.job.sfid}/send-customer-quote`,
      );
    },
    sendEmailOrderQuoteDomainIssue() {
      return axios.post('/api/admin/send-email', {
        template_name: 'customer/order_quote_domainissue',
        job_id: this.value.job.sfid,
      });
    },
    sendEmailPartnerNotification() {
      return axios.post('/api/admin/send-email', {
        template_name: 'partner/order_offer',
        recipient_only_profiles: ['admin'],
        job_id: this.value.job.sfid,
      });
    },
    sendEmailDKVMissingSapNumber() {
      return axios.post('/api/admin/send-email', {
        template_name: 'brand/dkv/missing_sap_number',
        job_id: this.value.job.sfid,
      });
    },
    sendReminderAbsentCustomer() {
      return axios.post('/api/admin/send-email', {
        template_name: 'customer/missing_presence',
        job_id: this.value.job.sfid,
      });
    },
    sendReminderJobModified() {
      return axios.post('/api/admin/send-email', {
        template_name: 'customer/order_modification',
        job_id: this.value.job.sfid,
      });
    },
    createBrandQuote() {
      return axios.get(
        `/api/admin/jobs/${this.value.job.sfid}/send-brand-quote`,
      );
    },
    createProposal() {
      let routeData = this.$router.resolve({
        name: 'job-followup',
        query: { id: this.value.job.sfid },
      });
      window.open(routeData.href, '_blank');
      return Promise.resolve();
    },
    createReclamation() {
      let routeData = this.$router.resolve({
        name: 'job-reclamation',
        query: { id: this.value.job.sfid },
      });
      window.open(routeData.href, '_blank');
      return Promise.resolve();
    },
    createAlternative() {
      let routeData = this.$router.resolve({
        name: 'jobs-create-alternative',
        query: { id: this.value.job.sfid },
      });
      window.open(routeData.href, '_blank');
      return Promise.resolve();
    },
    onCommentAction() {
      if (!this.change) {
        this.$emit('comment-action');
      }
    },
    sendJobServiceEvent({ event, inputs }) {
      return axios
        .post(
          `/jobs-api/status-rules/job/${this.value.job.api_id}/apply-event`,
          {
            type: event,
            ...inputs,
          },
        )
        .then(() => {
          return this.init();
        })
        .then(() => {
          this.$emit('input', this.item);
          this.status = this.item.job.status;
        });
    },
    onModalClose() {
      this.showAddTicket = false;
    },
    onRefreshTickets() {
      this.refreshTickets = true;

      this.$nextTick(() => {
        this.refreshTickets = false;
      });
    },
  },
};
</script>

<style lang="scss">
.gid-error {
  pre {
    max-height: 35vh;
  }
}
.gid-more-actions .multiselect__option--group.multiselect__option--disabled {
  color: inherit !important;
}

.fade-scale-enter-active,
.fade-scale-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.fade-scale-enter,
.fade-scale-leave-to {
  opacity: 0;
  transform: scale(0.95); /* Slight zoom effect */
}
</style>
